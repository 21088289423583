import { Module, VuexModule, MutationAction, getModule, Mutation } from "vuex-module-decorators";
import store from "@/store";
import API from "@/store/API/dashboard";
import { Dashboard, SupplyDemandTrend } from "@/store/models/dashboard";

@Module({ name: "dashboardStore", dynamic: true, store })
class DashboardModule extends VuexModule {
  public Dashboard: Dashboard = {} as Dashboard;
  public SupplyDemandTrend: SupplyDemandTrend = {} as SupplyDemandTrend;

  @MutationAction
  async getDashboardData() {
    const dashboard = await API.getDashboardData();
    return { Dashboard: dashboard as Dashboard };
  }

  @MutationAction
  async getSupplyDemandTrendData(params: { varietyId: number; seedTypeId: number }) {
    let query = "?";
    if (params.varietyId) {
      query += "&varietyid=" + params.varietyId;
    }
    if (params.seedTypeId) {
      query += "&seedtype=" + params.seedTypeId;
    }
    const supplyDemandTrend = await API.getSupplyDemandTrendData(query);
    return { SupplyDemandTrend: supplyDemandTrend as SupplyDemandTrend };
  }
}

export default getModule(DashboardModule);
