import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "@/store";

@Module({ name: "Loaderstore", dynamic: true, store })
class Loaderstore extends VuexModule {
  public loading: boolean = false;
  public loadingCropVarieties: boolean = false;
  public loadingCropVarietyDetail: boolean = false;

  @Mutation
  showLoading() {
    this.loading = true;
  }

  @Mutation
  hideLoading() {
    this.loading = false;
  }

  @Mutation
  showLoadingCropVarieties() {
    this.loadingCropVarieties = true;
  }

  @Mutation
  hideLoadingCropVarieties() {
    this.loadingCropVarieties = false;
  }

  @Mutation
  showLoadingCropVarietyDetail() {
    this.loadingCropVarietyDetail = true;
  }

  @Mutation
  hideLoadingCropVarietyDetail() {
    this.loadingCropVarietyDetail = false;
  }
}

export default getModule(Loaderstore);
