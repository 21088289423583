import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
  MutationAction
} from "vuex-module-decorators";
import compare from "@/store/API/compare";
import { CompareCrops, DownloadCompareCrops } from "@/store/models/cropVariety";
import { SelectedVariable } from "@/store/models/compare";

@Module({ name: "compareStore", dynamic: true, store })
class CompareCrop extends VuexModule {
  public isHidden: boolean = true;
  checkedCrops: string = "";
  CropVarieties: CompareCrops = {} as CompareCrops;
  DownloadCropVarieties: DownloadCompareCrops = {} as DownloadCompareCrops;
  selectedCrops: SelectedVariable[] = [];

  @MutationAction
  async compareCropDetail(param: { ids: any; lang?: string }): Promise<any> {
    const response = await compare.compareCropDetail(param);
    return { CropVarieties: response as any };
  }

  @MutationAction
  async downloadCompareCropDetail(param: {
    ids: any;
    lang?: string;
    download?: boolean;
  }): Promise<any> {
    const response = await compare.downloadCompareCropDetail(param);
    return { DownloadCropVarieties: response as any };
  }

  @Mutation
  async clearSelectedCrops(): Promise<any> {
    this.selectedCrops = [];
  }

  @Mutation
  async addToSelectedCrops(newVariable: SelectedVariable): Promise<any> {
    if (this.selectedCrops.length < 3) {
      this.selectedCrops.push(newVariable);
    } else {
      this.selectedCrops.splice(0, 1);
      this.selectedCrops.push(newVariable);
    }
  }

  @Mutation
  async removeFromSelectedCrops(id: number): Promise<any> {
    let index = this.selectedCrops.findIndex(x => x.id == id);
    this.selectedCrops.splice(index, 1);
  }

  @Mutation
  toggleCheckbox() {
    this.isHidden = !this.isHidden;
  }
}

export default getModule(CompareCrop);
