import store from "@/store";
import { Module, VuexModule, MutationAction, getModule, Action } from "vuex-module-decorators";
import API from "@/store/API/report";
import { CategoryReportPaginated } from "@/store/models/report";

@Module({ name: "reportStore", dynamic: true, store })
class ReportModule extends VuexModule {
  public CategoryReportPaginated: CategoryReportPaginated = {} as CategoryReportPaginated;
  public AdHocReportList: any[] = [];

  @MutationAction
  async getCategoryReport(params: {
    page?: number;
    fiscalYearId?: number;
    cropCategoryId?: number;
    seedTypeId?: number;
  }) {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropCategoryId) {
        query += "&cropcategoryid=" + String(params.cropCategoryId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
    }
    let categoryReport = await API.getCategoryReport(query);
    return { CategoryReportPaginated: categoryReport as CategoryReportPaginated };
  }

  @MutationAction
  async getAdHocReport() {
    let adHocReport = await API.getAdHocReport();
    return { AdHocReportList: adHocReport as any[] };
  }

  @Action
  async getPostSupplyReport(params: {
    fiscalYearId: number;
    cropTypeId: number;
    cropVarietyId: number | null;
    seedTypeId: number;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropTypeId) {
        query += "&croptypeid=" + String(params.cropTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.cropTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
    }
    return await API.getPostSupplyReport(query);
  }

  @Action
  async getPostDemandReport(params: {
    fiscalYearId: number;
    cropTypeId: number;
    cropVarietyId: number | null;
    seedTypeId: number;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropTypeId) {
        query += "&croptypeid=" + String(params.cropTypeId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.cropTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
    }
    return await API.getPostDemandReport(query);
  }
}

export default getModule(ReportModule);
