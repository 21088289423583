import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
  MutationAction
} from "vuex-module-decorators";
import API from "@/store/API/comment";
import { CropVarietyComment, ReplyData, PaginatedCropVarietyComment, CommentDetail, UnseenStatus } from "@/store/models/comment";
import Vue from 'vue';

@Module({ name: "commentStore", dynamic: true, store })
class CommentStore extends VuexModule {
  CommentList: CropVarietyComment[] = [] as CropVarietyComment[];
  ReplyList: ReplyData = {} as ReplyData;
  AllComments: PaginatedCropVarietyComment = {} as PaginatedCropVarietyComment;
  CommentDetail: CommentDetail = {} as CommentDetail;
  UnseenStatus: UnseenStatus = {} as UnseenStatus;


  @MutationAction
  async getAllCommentsOnly(params: {
    page?: number;
    cropId?: number;
    cropVarietyId?: number;
    seen?: boolean;
    own?: boolean;
    searchUser?: string;
  }): Promise<any> {
    let query = "?comment_only=true"

    if (params) {
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.cropId) {
        query += "&croptypeid=" + String(params.cropId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.seen) {
        query += "&seen=" + String(params.seen);
      }
      if (params.searchUser) {
        query += "&username=" + String(params.searchUser);
      }
    }
    const response = await API.getAllComments(query);
    return { AllComments: response as PaginatedCropVarietyComment };
  }

  @Mutation
  async deleteFromAllComments(id: number) {
    const cropvarietycomment = await API.deleteComment(id);
    let index = this.AllComments.results.findIndex(x => x.id == id);
    if (index >= 0) {
      this.AllComments.results.splice(index, 1);
    }
  }


  @MutationAction
  async getCommentDetailById(id: number): Promise<any> {
    const response = await API.getCommentDetailById(id);
    return { CommentDetail: response as CommentDetail };
  }

  @MutationAction
  async getCommentsByCropVarietyId(id: number): Promise<any> {
    const response = await API.getCommentsByCropVarietyId(id);
    return { CommentList: response as CropVarietyComment[] };
  }

  @Mutation
  async saveComment(comment: CropVarietyComment) {
    const cropvarietycomment = await API.saveComment(comment);
    // return { CommentList: cropvarietycomment as CropVarietyComment[] };
    if (comment.id && comment.id != 0) {
      let index = this.CommentList.findIndex(x => x.id == comment.id);
      this.CommentList.splice(index, 1, cropvarietycomment)
    } else {
      this.CommentList.splice(0, 0, cropvarietycomment)
    }
  }

  // @Mutation
  // increaseReplyCount(id: number) {
  //   let index = this.CommentList.findIndex(x => x.id == id);
  //   this.CommentList[index].reply_count++;
  //   // this.CommentList.splice(index, 1, comment);
  // }

  @Mutation
  async deleteComment(id: number) {
    const cropvarietycomment = await API.deleteComment(id);
    let index = this.CommentList.findIndex(x => x.id == id);
    this.CommentList.splice(index, 1);
    if (this.ReplyList[id] && this.ReplyList[id].length > 1) {
      this.ReplyList[id] = []
    }

    // return { CommentList: cropvarietycomment as CropVarietyComment[] };
  }

  @Mutation
  async changeToSeen(params: { commentId: number, seen?: boolean }) {
    let data: { id: number, commentid: number, seen: boolean } = { id: 0, commentid: params.commentId, seen: true }
    if (params.seen) {
      data['seen'] = params.seen;
    }
    await API.changeToSeen(data);
    let allComments = this.AllComments.results.find(x => x.id == params.commentId);
    if (allComments) {
      allComments.seen = data['seen'];
    }
  }

  @MutationAction
  async getCommentUnseenCount() {
    const response = await API.getCommentUnseenCount();
    console.log(response)
    return { UnseenStatus: response as UnseenStatus };
  }

  @MutationAction
  async getRepliesByCropVarietyId(id: number): Promise<any> {
    const response = await API.getRepliesByCropVarietyId(id);
    return { ReplyList: response as ReplyData };
  }

  @Mutation
  async saveReply(comment: CropVarietyComment) {
    const cropvarietycomment = await API.saveComment(comment);
    if (comment.parentid && comment.parentid != null) {
      if (comment.parentid in this.ReplyList) {
        let data = this.ReplyList[comment.parentid]
        if (comment.id && comment.id != 0) {
          let index = data.findIndex(x => x.id == comment.id);
          data.splice(index, 1, cropvarietycomment[comment.parentid])
        } else {
          data.splice(0, 0, cropvarietycomment[comment.parentid])
        }
      } else {
        Vue.set(this.ReplyList, comment.parentid, []);
        this.ReplyList[comment.parentid].push(cropvarietycomment[comment.parentid])
      }
    }
    // return { ReplyList: cropvarietycomment as ReplyData };
  }

  @Mutation
  async deleteReply(params: { id: number, parentid: number }) {
    const cropvarietycomment = await API.deleteComment(params.id);
    if (this.ReplyList[params.parentid]) {
      let index = this.ReplyList[params.parentid].findIndex(x => x.id == params.id)
      this.ReplyList[params.parentid].splice(index, 1);
    }
    // return { ReplyList: cropvarietycomment as ReplyData };
  }
}

export default getModule(CommentStore);
