import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
  Action,
  getModule
} from "vuex-module-decorators";
import API from "@/store/API/user";
import {
  User,
  Role,
  PaginatedUser,
  PaginatedCompanyUser,
  CompanyUser,
  SendMail,
  DSADgroup,
  SeedClass
} from "@/store/models/user";
import { SeedType } from "../models/DemandAndSupply";

@Module({ name: "userStore", dynamic: true, store })
class UserModule extends VuexModule {
  public Roles: Role[] = [];
  public Users: User[] = [];
  public AdminUsers: PaginatedUser = {} as PaginatedUser;
  public GeneralUsers: PaginatedUser = {} as PaginatedUser;
  public SeedCompany: PaginatedCompanyUser = {} as PaginatedCompanyUser;
  public ConsumerCompany: PaginatedCompanyUser = {} as PaginatedCompanyUser;
  public User: User = {} as User;
  public SendMail: SendMail[] = [] as SendMail[];
  public DSADgroup: DSADgroup[] = [] as DSADgroup[];

  @MutationAction
  async getUserList() {
    const users = await API.getAllUserList();
    return { Users: users as User[] };
  }

  @MutationAction
  async getAdminUserList(
    params: { searchString?: any; page?: any } = { searchString: undefined, page: 1 }
  ) {
    let query = "/";
    if (params.searchString || params.page) {
      query += "?";
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getAdminUserList(query);
    return { AdminUsers: paginatedUsers as PaginatedUser };
  }

  @MutationAction
  async getGeneralUserList(
    params: { searchString?: any; page?: any } = { searchString: undefined, page: 1 }
  ) {
    let query = "/";
    if (params.searchString || params.page) {
      query += "?";
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getGeneralUserList(query);
    return { GeneralUsers: paginatedUsers as PaginatedUser };
  }

  @MutationAction
  async getSeedCompanyUserList(
    params: { searchString?: any; page?: any; approved?: any } = {
      searchString: undefined,
      page: 1
    }
  ) {
    let query = "/";
    if (params.searchString || params.page || params.approved) {
      query += "?";
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    if (params.approved) {
      query += "&approved=" + params.approved;
    }
    const paginatedUsers = await API.getSeedCompanyList(query);
    return { SeedCompany: paginatedUsers as PaginatedCompanyUser };
  }

  @MutationAction
  async getConsumerCompanyUserList(
    params: { searchString?: any; page?: any; approved?: any } = {
      searchString: undefined,
      page: 1
    }
  ) {
    let query = "/";
    if (params.searchString || params.page || params.approved) {
      query += "?";
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    if (params.approved) {
      query += "&approved=" + params.approved;
    }
    const paginatedUsers = await API.getConsumerCompanyList(query);
    return { ConsumerCompany: paginatedUsers as PaginatedCompanyUser };
  }

  @Action
  async registerCompany(params: any) {
    return await API.registerCompany(params);
  }

  @Action
  async EditCompanyProfile(params: any) {
    return await API.EditCompanyProfile(params);
  }

  @Action
  async getCompanyByUserId(id: number) {
    return await API.getCompanyByUserId(id);
  }

  @Action
  async approveCompanyById(params: { id: number; status?: any; seedtype?: any }) {
    let res = await API.approveCompany({
      id: params.id,
      status: params.status,
      seedtype: params.seedtype
    });
  }

  @MutationAction
  async rejectCompanyById(params: any) {
    var userListing = (this.state as UserModule).ConsumerCompany;
    let obj = await API.rejectCompany(params);
    let index = userListing.results.findIndex(x => x.id == params.id);
    userListing.results.splice(index, 1, obj);
    return { ConsumerCompany: userListing as PaginatedCompanyUser };
  }
  // @MutationAction
  // async loadUsers(forced: boolean = false) {
  // var userListing = (this.state as UserModule).Users;
  //     const user = await API.loadUsers();
  //     return { Users: user as User[]};
  // }

  // @MutationAction
  // async saveUsers(userList: User[]) {
  //   const list = await API.saveUsers(userList);
  //   return { Users: list as User[] };
  // }

  @Mutation
  updateUserList(param: { user: User; index: number }) {
    this.Users.splice(param.index, 1, param.user);
  }

  @Mutation
  pushUserList(user: User) {
    this.Users.push(user);
  }

  // @Mutation
  // async deleteCustomUser(params: { id: number; index: number }) {
  //   if (params.id !== 0) {
  //     const user = await API.deleteCustomUser(params.id);
  //   }
  //   this.Users.splice(params.index, 1);
  //   return true;
  // }

  // @MutationAction
  // async searchUsers(params: { users: User[]; searchString?: any; page?: any }) {
  //   API.saveUsers(params.users);
  //   let query = "/";
  //   if (params.searchString || params.page) {
  //     query += "?";
  //   }
  //   if (params.searchString) {
  //     query += "&search=" + params.searchString;
  //   }
  //   if (params.page) {
  //     query += "&paging=true&page=" + params.page;
  //   }
  //   const res = (await API.loadUsers(query)) as Page;
  //   const list = res.results;
  //   return { Users: list as User[], Page: res as Page };
  // }

  //End

  // @MutationAction
  // async loadRoles() {
  //   var roleListing = (this.state as UserModule).Roles;
  //     roleListing = await API.loadRoles();
  //   return { Roles: roleListing as Role[] };
  // }

  @MutationAction
  async getUser() {
    const user = await API.getUser();
    return { User: user as User };
  }

  // @MutationAction
  // async getRole() {
  //   const user = await API.loadRoles();
  //   return { User: user as User };
  // }

  @Mutation
  updateUser(user: any) {
    this.User = user;
  }

  @MutationAction
  public async changeProfilePicture(saveData: any): Promise<any> {
    let user = await API.changeProfilePicture(saveData);
    return { User: user as User };
  }

  @Mutation
  public async deleteCompanyUserById(id: number): Promise<any> {
    let user = await API.deleteCompanyUserById(id);
    let index = this.ConsumerCompany.results.findIndex(x => x.id == id);
    this.ConsumerCompany.results.splice(index, 1);
  }

  @Mutation
  public async deleteCustomUserById(id: number): Promise<any> {
    let user = await API.deleteCustomUserById(id);
    let index = this.GeneralUsers.results.findIndex(x => x.id == id);
    this.GeneralUsers.results.splice(index, 1);
  }

  @MutationAction
  async saveAdminUser(params: User) {
    const user = await API.saveAdminUser(params);
    return { AdminUsers: user as PaginatedUser };
  }

  // @MutationAction
  // async sendmaillogs(): Promise<any> {
  //   let sendmailList = await API.sendmaillogs();
  //   return { SendMail: sendmailList as SendMail };
  // }

  @MutationAction
  async sendmaillogs(params: {
    page?: number;
    fiscalYearId?: number;
    transactionName?: string;
    groupId?: number;
    cropVarietyId?: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.transactionName) {
        query += "&transactionname=" + String(params.transactionName);
      }
      if (params.groupId) {
        query += "&groupid=" + String(params.groupId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
    }
    let sendmailList = await API.sendmaillogs(query);
    return { SendMail: sendmailList as SendMail };
  }

  @MutationAction
  async dsadgroup(): Promise<any> {
    let dsadgroup = await API.dsadgroup();
    return { DSADgroup: dsadgroup as DSADgroup };
  }
}

export default getModule(UserModule);
